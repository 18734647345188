import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>
        <p>Error 404</p> Page Not Found
      </h1>
    </div>
  );
};

export default NotFound;
